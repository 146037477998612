<template>
  <div class="aviationOTC">
    <div v-if="!isLoading">
      <ScAviationOTCTab
        v-if="isAuthenticated"
        :aviation-documents="aviationDocuments"
        :revision-heading="translations.scTabs.scOtcTab.revisionHeading"
        :heading="translations.scTabs.scOtcTab.manualsHeading"
        :version-label="translations.scTabs.scOtcTab.versionLabel"
        :no-results="translations.scTabs.scOtcTab.noResults"
        :no-manuals="translations.scTabs.scOtcTab.noManualsInSelectedLanguage"
        :compliance-heading="translations.scTabs.scOtcTab.complianceHeading"
        :doc-heading="translations.scTabs.scOtcTab.docHeading"
        :versions-default-label="translations.scTabs.scOtcTab.versionsDefaultLabel"
        :available-documents="translations.scTabs.scOtcTab.availableDocuments"
        :not-reg-heading="translations.scTabs.scOtcTab.notRegHeading"
        :not-reg-content="translations.scTabs.scOtcTab.notRegContent"
        :not-reg-link-text="translations.scTabs.scOtcTab.notRegLinkText"
        :not-reg-link="translations.scTabs.scOtcTab.notRegLink"
        :content-summary-translations="contentSummaryTranslations"
        :keyword-label="translations.scTabs.scOtcTab.keywordSearchLabel"
        :no-results-messaging="translations.scTabs.scOtcTab.noResultsMessaging"
        :keyword-search-attr="{ placeholder: translations.scTabs.scOtcTab.keywordSearchPlaceholder }"
        :product-name="product.displayName"
      />
      <div
        v-else
        class="aviationOTC--logged-out"
      >
        <h2 v-if="translations.scTabs.scOtcTab.loggedOutHeading">
          {{ translations.scTabs.scOtcTab.loggedOutHeading }}
        </h2>
        <p>
          {{ translations.scTabs.scOtcTab.loginMessaging }}
        </p>
        <a
          v-if="translations.scTabs.scOtcTab.loginLinkText"
          :href="getAccountLoginUrl()"
          target="_self"
          @click.prevent="onHelpLinkClick({
            event_category: 'Product - Aviation OTC',
            event_action: 'Link',
            event_label: `Login`,
            page_type: 'Product - Aviation OTC',
            link_type: 'Link',
            link: getAccountLoginUrl()
          })"
        >
          {{ translations.scTabs.scOtcTab.loginLinkText }}
        </a>
      </div>
    </div>
  </div>
</template>

<script src="./index.js"></script>
